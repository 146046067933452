<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="5" class="pa-0">
        <v-responsive height="calc(100vh - 112px)" class="pt-5 pb-3 px-4">
          <v-card height="100%" class="d-flex flex-column">
            <v-card-title>Associa:</v-card-title>
            <v-card-text style="flex-grow: 1">
              <v-form v-model="valid" ref="form">
                <v-select
                  label="Ambiente"
                  v-model="selectedAmbient"
                  @change="validaTrigger"
                  :items="$store.state.ambienti"
                  :rules="ambienteRule"
                ></v-select>
                <v-select
                  v-if="selectedAmbient == 'Trasporti'"
                  label="Tipo Barcode"
                  v-model="tipo_barcode"
                  :roule="ambienteRule"
                  :items="tipi_barcode"
                  item-text="text"
                  item-value="value"
                  @change="validaTrigger"
                ></v-select>
                <v-text-field
                  label="Barcode"
                  v-model="barcode"
                  @keyup="validaTrigger"
                  :rules="barcodeRule"
                ></v-text-field>
              </v-form>

              <BarcodeDesc v-if="barcode_validato" :dett="dettaglio_barcode" :barcode="barcode" :ambiente="selectedAmbient"/>
              
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="warning" @click="back"> Esci </v-btn>
              <v-btn
                :disabled="!valid || loading"
                :loading="loading"
                color="success"
                @click="associaDialog = true"
              >
                Conferma
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-responsive>
      </v-col>
      <v-col cols="12" md="7" class="pa-0">
        <v-responsive
          height="calc(100vh - 112px)"
          class="text-center pa-2 overflow-y-auto"
        >
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: 0.5,
            }"
            min-height="200"
            transition="fade-transition"
          >
            <v-container class="pr-0">
              <v-row>
                <v-col
                  v-for="photo in $store.state.selectedPhotos"
                  :key="photo['difile']"
                  cols="12"
                  lg="4"
                  md="6"
                  sm="12"
                  xs="12"
                >
                  <v-card
                    class="mx-auto text-left"
                    max-width="344"
                    @click="openDialogFullScreen(photo)"
                  >
                    <v-img
                      height="200px"
                      contain
                      :src="photo['link']"
                      class="grey darken-3"
                    ></v-img>
                    <v-card-title>{{ photo["difile"] }}</v-card-title>

                    <v-card-text>
                      <div class="my-4 text-subtitle-1">
                        Data e ora: {{ photo["didtai"] }},
                        {{ photo["dihmai"] }} <br />
                        Utente: {{ photo["dicdop"] }}
                      </div>
                    </v-card-text>
                  </v-card>
                  <!-- <v-card @click="openDialogFullScreen(photo['link'])">
                    <v-img :src="photo['link']"/>
                  </v-card> -->
                </v-col>
              </v-row>
            </v-container>
          </v-lazy>
        </v-responsive>
      </v-col>
    </v-row>

    <!-- POPUP DETTAGLIO -->
    <v-dialog v-model="image_fullscreen_dialog">
      <PhotoDetail ref="photoDetail" :photo="selectedImage"/>
      <v-btn class="x-button" icon @click="image_fullscreen_dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-dialog>

    <v-dialog v-model="associaDialog" max-width="350" persistent>
      <v-alert
        type="warning"
        prominent
        outlined
        elevation="2"
        class="mb-0"
      >
        Sei sicuro di voler associare le foto al documento
        <b>{{ dettaglio_barcode["nr_doc"] }}</b
        >?
        <div class="text-right">
          <v-btn small text color="secondary" @click="associaDialog = false">
            Chiudi
          </v-btn>
          <v-btn small text color="secondary" @click="associaImmagini()">
            Conferma
          </v-btn>
        </div>
      </v-alert>
    </v-dialog>
    <v-dialog v-model="confermaAssoc" max-width="350" persistent>
      <v-alert :type="alertType" prominent outlined elevation="2" class="mb-0">
        {{ text }}
        <div class="text-right">
          <v-btn
            small
            text
            color="secondary"
            @click="alertType == 'success' ? back() : (confermaAssoc = false)"
          >
            Ok
          </v-btn>
        </div>
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import router from "../router";
import PhotoDetail from '../components/PhotoDetail.vue';
import BarcodeDesc from '../components/BarcodeDesc.vue';

const _ = require("lodash");

export default {
  name: "Associa",
  components: {
    PhotoDetail,
    BarcodeDesc
  },
  data: () => ({
    isActive: false,
    barcode: "",
    barcode_validato: false,
    tipo_barcode: "B",
    selectedAmbient: null,
    valid: true,
    ambienteRule: [(v) => !!v || "Campo obbligatorio!"],
    dettaglio_barcode: {},
    selectedImage: null,
    image_fullscreen_dialog: false,
    loading: false,
    associaDialog: false,
    confermaAssoc: false,
    alertType: "",
    text: "",
    tipi_barcode: [{"text": "Segnacollo", "value": "B"},
                  {"text": "Lista di Carico", "value": "L"},
                  {"text": "Bordereau", "value": "X"},
                  {"text": "Foglio di Viaggio", "value": "F"}]
  }),
  created() {
    if (
      this.$store.state.selectedPhotos == null ||
      this.$store.state.selectedPhotos == undefined ||
      this.$store.state.selectedPhotos.length == 0
    ) {
      router.push("/");
    }
  },
  methods: {
    validaBarcode() {
      this.axios
        .post(`${this.$root.endpoint}barcode`, {
          cd_azienda: this.$store.state.cd_azienda,
          barcode: this.barcode,
          ambiente: this.selectedAmbient,
          tipo_barcode: this.tipo_barcode
        })
        .then((response) => {
          this.barcode_validato = true;
          this.dettaglio_barcode = response.data;
          this.$refs.form.validate();
          this.loading = false;
        })
        .catch(() => {
          this.barcode_validato = false;
          this.$refs.form.validate();
          this.loading = false;
        });
    },
    validaTrigger() {
      this.loading = true;
      this.decounceValida();
    },
    decounceValida: _.debounce(function () {
      // funzione di ricerca debounced
      this.validaBarcode();
    }, 300),
    openDialogFullScreen(photo) {
      this.selectedImage = photo;
      this.image_fullscreen_dialog = true;
      this.$nextTick(() => {
        this.$refs.photoDetail.updateDetail()
      })
    },
    async associaImmagini() {
      this.associaDialog = false;
      this.loading = true;
      var promise_array = [];
      for (const element of this.$store.state.selectedPhotos) {
        var base64_img = await this.getBase64Image(element.difile);

        promise_array.push(
          new Promise((resolve,reject) => {
            var array_foto = element.difile.split("_")
            var data_immagine = array_foto[array_foto.length-1].split(".")[0]

            this.axios
              .post(`${this.$root.endpoint}foto`, {
                cd_azienda: this.$store.state.cd_azienda,
                barcode: this.barcode,
                motivazione: element.dicavs,
                descrizione: element.didesa,
                ambiente: this.selectedAmbient,
                username: element.diuser,
                tipo_barcode: this.dettaglio_barcode["tipo_barcode"],
                foto: [
                  {
                    key: data_immagine,
                    src: base64_img,
                  },
                ],
              })
              .then(() => {
                this.axios
                  .delete(
                    `${this.$root.endpoint}foto?cd_azienda=${this.$store.state.cd_azienda}&nome_foto=${element.difile}&gestita=1`
                  )
                  .then(() => {
                    resolve("OK");
                  })
                  .catch(() => {
                    reject(element.difile);
                  });
              })
              .catch(() => {
                reject(element.difile);
              });
          })
        );
      }

      Promise.all(promise_array)
        .then(() => {
          this.alertType = "success";
          this.text = "Foto associate con successo";
          this.confermaAssoc = true;
        })
        .catch(() => {
          this.alertType = "error";
          this.text = "Impossibile associare le foto";
          this.confermaAssoc = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getBase64Image(img) {
      // Access-Control-Allow-Headers
      return new Promise((resolve) => {
        this.axios
          .post(`${this.$root.endpoint}base64`, {
            cd_azienda: this.$store.state.cd_azienda,
            nome_foto: img,
            ambiente: "A",
          })
          .then((response) => {
            resolve(response.data.base64);
          });
      });
    },
    back() {
      this.confermaAssoc = false;
      this.$store.state.selectedPhotos = null;
      router.push("/");
    },
  },
  computed: {
    barcodeRule() {
      return [(v) => (!!v && this.barcode_validato) || `Barcode non valido!`];
    },
  },
};
</script>

<style scoped>
.v-alert--outlined {
  background-color: white !important;
}
</style>