import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedPhotos: [],
    background: '',
    logo: '',
    link_tracking: '',
    cd_azienda: '',
    username: '',
    documento: '',
    ambiente: '',
    ambienti: [],
    loaded: true
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
